import React, { useState, useEffect } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { collection, doc, getDoc, query, getDocs } from 'firebase/firestore'
import { Alert } from 'react-alert'
import { DateTimePicker } from '@mui/x-date-pickers'
import * as moment from 'moment'
import 'moment/locale/fr'
import { auth } from '../../firebase'

import BlackLogo from '../../components/BlackLogo'
import updateCustomer from '../../utility/updateCustomer'
import Input from '../../components/Input'
import LoadingSpinner from '../../components/LoadingSpinner.js'
import { db } from '../../firebase'

const AjoutNote = () => {
	const navigate = useNavigate()
	const location = useLocation()
	const { customerId } = useParams()
	const [isLoading, setIsLoading] = useState(false)
	const [vendeurId, setVendeurId] = useState()
	const [customer, setCustomer] = useState()
	const [note, setNote] = useState()
	const [stat, setStat] = useState()
	const [action, setAction] = useState()
	const [date, setDate] = useState()
	const [project, setProject] = useState()
	const [title, setTitle] = useState('Cuisine')
	const [errors, setErrors] = useState({})
	const [showrooms, setShowrooms] = useState([])
	const [currentUser, setCurrentUser] = useState()
	const [sales, setSales] = useState([
		{
			label: 'Vendeur',
			value: false,
		},
	])
	const [vendeurName, setVendeurName] = useState()

	moment.locale('fr-FR')

	const getCustomer = async () => {
		const customerRef = doc(db, 'Customers', customerId)

		getDoc(customerRef)
			.then((loadedCustomer) => {
				if (loadedCustomer.data()) {
					console.log(loadedCustomer.data())
					setCustomer(loadedCustomer.data())
					setVendeurId(loadedCustomer.data().vendeur)
					setVendeurName(loadedCustomer.data().vendeur)
				} else {
					alert("Ce client n'existe pas.", {
						type: 'error',
					})
				}
			})
			.catch((err) =>
				alert("Ce client n'existe pas.", {
					type: 'error',
				})
			)
	}

	const getSales = async () => {
		try {
			const salesRef = collection(db, 'Users')
			console.log(salesRef)

			const querySnapshot = await getDocs(salesRef)
			const salesData = querySnapshot.docs.map((doc) => ({
				label: doc.data().username,
				value: doc.data().username,
			}))

			// Find the index of the first element with value equal to vendeurName
			console.log('set Sales')
			console.log(salesData)
			setSales([
				{
					label: 'Vendeur',
					value: false,
				},
				...salesData,
			])
		} catch (error) {
			console.error('Error fetching sales:', error)
		}
	}

	useEffect(() => {
		const unsubscribe = auth.onAuthStateChanged(async (user) => {
			if (!user) {
				// User is authenticated, allow access to protected routes
				navigate('/login')
			} else {
				const uid = user.uid

				// Check if document with ID equal to uid exists in the "Store" collection
				const storeRef = doc(collection(db, 'Users'), uid)
				const docSnapshot = await getDoc(storeRef)
				const userData = docSnapshot.data()
				setCurrentUser(userData)
			}
		})

		return () => unsubscribe()
	}, [navigate])

	const handleSkip = () => {
		setNote('SKIPPED')
		setStat('B') // Set to 'B' directly instead of statData[0].value
		setAction('Call') // Set to 'Call' directly
		setDate(moment().format('LLL')) // Current date/time formatted
		setProject('Autre') // Set to 'Autre'
		setTitle('Autres') // Set to 'Autres'
		setVendeurName(vendeurId || '') // Keep the current vendeur

		// You might want to submit the form automatically after setting these values
		setTimeout(() => {
			document
				.querySelector('form')
				.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))
		}, 100)
	}

	useEffect(() => {
		getCustomer()
		getSales()
	}, [title])

	const inputChangeHandler = (
		changeEvent: React.ChangeEvent<HTMLInputElement>
	) => {
		switch (changeEvent.target.id) {
			case 'Stat':
				setStat(changeEvent.target.value)
				break
			case 'Action':
				setAction(changeEvent.target.value)
				break
			case 'Date':
				setDate(changeEvent.target.value)
				break
			case 'Project':
				setProject(changeEvent.target.value)
				break
			case 'Title':
				setTitle(changeEvent.target.value)
				break
			case 'Note':
				setNote(changeEvent.target.value)
				break
			case 'Sales':
				setVendeurName(changeEvent.target.value)
				break
			default:
				console.log(`Not found ${changeEvent.target.id}.`)
		}
	}

	const handleSubmit = async (e) => {
		e.preventDefault()

		let formErrors = {}

		if (!date) {
			alert('Date est nécessaire!')
			return
		}

		if (Object.keys(formErrors).length > 0) {
			setErrors(formErrors)
			return
		}

		const selectedShowroom = currentUser.selectedShowroom

		if (stat) {
			setIsLoading(true)
			const updated = await updateCustomer(
				customerId,
				note,
				date,
				stat,
				action,
				project,
				title,
				selectedShowroom,
				vendeurName || vendeurId
			)
			if (updated) {
				setNote('')
				setStat()
				setCustomer()
				setAction()
				setDate()
				setProject()
				setTitle()
				setIsLoading(false)
				navigate(
					location.search == '?scanned'
						? '/scan-confirmation'
						: `/confirmation/${vendeurId}`
				)
			} else {
				alert("La mise à jour du client n'a pas fonctionnée.", {
					type: 'error',
				})
			}
		} else {
			navigate(
				location.search == '?scanned'
					? '/scan-confirmation'
					: `/confirmation/${vendeurId}`
			)
		}
		setIsLoading(false)
	}

	return (
		<div className="mobile-screen-wrapper">
			<form onSubmit={handleSubmit} className="wrapper wrapper-inner">
				<div className="gap-border mb-3"></div>
				<h2 className="title--small" style={{ marginBottom: '33px' }}>
					Ajouter <br /> une note
				</h2>
				<div className="ajout-note-info">
					<div className="item">
						<strong>Nom: </strong> <span>{customer ? customer.name : ''}</span>
					</div>
					<div className="item">
						<strong>Email: </strong>{' '}
						<span>{customer ? customer.mail : ''}</span>
					</div>
					<div className="item">
						<strong>Prénom : </strong>{' '}
						<span>{customer ? customer.firstName : ''}</span>
					</div>
					<div className="item">
						<strong>Numéro: </strong>{' '}
						<span>{customer ? customer.phoneNumber : ''}</span>
					</div>
					<div className="item">
						<Input
							id="Stat"
							style={{ width: '90%', height: '56px' }}
							onChange={(e) => inputChangeHandler(e)}
							required="required"
							select={statData}
						/>
					</div>
					<div className="item">
						<Input
							type="text"
							style={{ width: '100%', height: '56px' }}
							id="Action"
							onChange={(e) => inputChangeHandler(e)}
							required="required"
							select={actionData}
						/>
					</div>
					<div className="item">
						<Input
							// label="Je suis à la recherche d'informations pour un nouveau projet"
							style={{ width: '90%', height: '56px', marginTop: '-1rem' }}
							type="text"
							select={selectData}
							id="Project"
							onChange={(e) => inputChangeHandler(e)}
							required="required"
						/>
					</div>
					<div className="item">
						<DateTimePicker
							label="Date/Heure"
							type="text"
							style={{ width: '100%' }}
							id="Date"
							onChange={(e) => setDate(moment(e).format('LLL'))}
							required="required"
							PopperProps={{
								sx: {
									'&.MuiPickersPopper-root': {
										border: '1px solid #e5e5e5 !important',
									},
								},
							}}
						/>
					</div>
					<div className="item">
						<Input
							type="text"
							style={{ width: '90%', height: '56px', marginTop: '-1rem' }}
							select={selectData2}
							id="Title"
							onChange={(e) => inputChangeHandler(e)}
							required="required"
						/>
					</div>
					<div className="item">
						<Input
							type="text"
							style={{ width: '100%', height: '56px', marginTop: '-1rem' }}
							select={sales}
							id="Sales"
							onChange={(e) => inputChangeHandler(e)}
							value={vendeurName}
							required="required"
						/>
					</div>
				</div>
				<div className="item">
					{/* <Input
              placeholder="Date/Heure"
              type="text"
              style={{ width: "90%" }}
              id="Date"
              onChange={(e) => inputChangeHandler(e)}
              required="required"
            /> */}
				</div>
				<br />
				<textarea
					placeholder="Note..."
					className="form-control form--control mb-3"
					type="text"
					id="Note"
					value={note}
					onChange={(e) => inputChangeHandler(e)}
					required="required"
				></textarea>
				<div className="text-1 mb-3">Brève description du visiteur</div>
				<div className="__content-236 mt-auto mb-39px">
					{isLoading ? (
						<div className="cmn-btn w-100">
							<LoadingSpinner height="80%" width="30%" />
						</div>
					) : (
						<>
							<button className="cmn-btn mb-2 w-100" type="submit">
								Valider
							</button>
							<button
								className="cmn-outline-btn w-100"
								type="button"
								onClick={handleSkip}
							>
								Skip Encodage
							</button>
						</>
					)}
				</div>
				<BlackLogo />
			</form>
		</div>
	)
}

const selectData = [
	{
		label: 'Type',
		value: '',
	},
	{
		label: 'Neuf',
		value: 'Neuf',
	},
	{
		label: 'Rénovation',
		value: 'Rénovation',
	},
	{
		label: 'Immobilier',
		value: 'Immobilier',
	},
	{
		label: 'Inactif',
		value: 'Inactif',
	},
	{
		label: 'Autre',
		value: 'Autre',
	},
]

const statData = [
	{
		label: 'Stat: A, B, C, D',
		value: '',
	},
	{
		label: 'A',
		value: 'A',
	},
	{
		label: 'B',
		value: 'B',
	},
	{
		label: 'C',
		value: 'C',
	},
	{
		label: 'D',
		value: 'D',
	},
]

const actionData = [
	{
		label: 'Action: RDV/Call',
		value: '',
	},
	{
		label: 'RDV',
		value: 'RDV',
	},
	{
		label: 'Call',
		value: 'Call',
	},
]

const selectData2 = [
	{
		label: 'Cuisine',
		value: 'Cuisine',
	},
	{
		label: 'Placards',
		value: 'Placards',
	},
	{
		label: 'Dressing',
		value: 'Dressing',
	},
	{
		label: 'Concours',
		value: 'Concours',
	},
	{
		label: 'Autres',
		value: 'Autres',
	},
]

export default AjoutNote
