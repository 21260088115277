import React, { useState, useEffect } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import {
	collection,
	onSnapshot,
	query,
	getDoc,
	getDocs,
	doc,
} from 'firebase/firestore'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import FormHelperText from '@mui/material/FormHelperText'
import FormControl from '@mui/material/FormControl'

import banner from '../../assets/img/banner.png'
import logo from '../../assets/img/logo-white.png'
import BlackLogo from '../../components/BlackLogo'
import FormulaireTelVendeur from './FormulaireTelVendeur'
import ScanCode from './ScanCode'
import Resultats from './Resultats'
import { auth, db } from '../../firebase'
import updateUser from '../../utility/users/updateUser'
import LoadingSpinner from '../../components/LoadingSpinner.js'
import DashboardSales from '../dashboard/DashboardSales'

const PartieVendeur = () => {
	const navigate = useNavigate()
	const [open, setOpen] = useState(false)
	const [open2, setOpen2] = useState(false)
	const [open3, setOpen3] = useState(false)
	const [password, setPassword] = useState('')
	const [authenticated, setAuthenticated] = useState(false)
	const [showrooms, setShowrooms] = useState([])
	const [selectedShowroom, setSelectedShowroom] = useState('')
	const [showroomError, setShowroomError] = useState(false)
	const [currentUser, setCurrentUser] = useState()
	const [isLoading, setIsLoading] = useState(false)

	const correctPassword = 'Streep#53'

	const checkPassword = (event) => {
		event.preventDefault()
		if (password === correctPassword) {
			setAuthenticated(true)
			localStorage.setItem('authenticated', true)
		} else {
			alert('Mot de passe incorrect. Réessayer !')
			setPassword('')
		}
	}

	const fetchShowrooms = async () => {
		try {
			setIsLoading(true)
			const q = query(collection(db, 'Showrooms'))

			let showroomsData = []

			const querySnapshot = await getDocs(q)
			if (querySnapshot.empty) {
				console.log('No documents found')
			} else {
				querySnapshot.forEach((doc) => {
					console.log(doc)
					showroomsData.push({
						name: doc.data().name,
						id: doc.id,
					})
				})
				setShowrooms(showroomsData)
				console.log(showroomsData)

				// Validate user's selectedShowroom against available showrooms
				if (currentUser?.selectedShowroom) {
					const showroomExists = showroomsData.some(
						(showroom) => showroom.name === currentUser.selectedShowroom
					)

					if (showroomExists) {
						setSelectedShowroom(currentUser.selectedShowroom)
						setShowroomError(false)
					} else {
						// Current user's showroom is not in the listing
						setSelectedShowroom('')
						setShowroomError(true)
					}
				} else {
					// No showroom selected yet
					setSelectedShowroom('')
					setShowroomError(true)
				}
			}
			console.log('Doc found')
		} catch (error) {
			console.error('Error fetching showrooms:', error)
		}
		setIsLoading(false)
	}

	useEffect(() => {
		const unsubscribe = auth.onAuthStateChanged(async (user) => {
			if (!user) {
				// User is authenticated, allow access to protected routes
				navigate('/login')
			} else {
				const uid = user.uid

				// Check if document with ID equal to uid exists in the "Store" collection
				const storeRef = doc(collection(db, 'Users'), uid)
				const docSnapshot = await getDoc(storeRef)
				const userData = docSnapshot.data()
				setCurrentUser(userData)
			}
		})

		return () => unsubscribe()
	}, [navigate])

	useEffect(() => {
		fetchShowrooms()
	}, [currentUser])

	const handleSelectShowroom = async (showroom) => {
		if (!showroom || !showrooms.some((item) => item.name === showroom)) {
			setShowroomError(true)
			return
		}

		setSelectedShowroom(showroom)
		setShowroomError(false)

		const updatedUser = { ...currentUser, selectedShowroom: showroom }
		console.log(updatedUser)
		try {
			await updateUser(updatedUser.uid, updatedUser)
		} catch (error) {
			console.log('Modification error:', error.message)
			alert("Modification de l'utilisateur échouée.")
		}
	}

	const handleButtonClick = (action) => {
		if (
			!selectedShowroom ||
			!showrooms.some((item) => item.name === selectedShowroom)
		) {
			setShowroomError(true)
			return
		}

		// Otherwise proceed with the action
		if (action === 'scan') {
			setOpen(true)
		} else if (action === 'new') {
			setOpen2(true)
		} else if (action === 'results') {
			setOpen3(true)
		}
	}

	function useWindowWidth() {
		const [windowWidth, setWindowWidth] = useState(window.innerWidth)

		useEffect(() => {
			function handleResize() {
				setWindowWidth(window.innerWidth)
			}

			window.addEventListener('resize', handleResize)
			return () => window.removeEventListener('resize', handleResize)
		}, [])

		return windowWidth
	}

	const windowWidth = useWindowWidth()
	const breakpoint = 768 // Define your breakpoint for mobile vs desktop

	return (
		<>
			{windowWidth < breakpoint ? (
				<>
					<div className="mobile-screen-wrapper">
						<div className="wrapper">
							<>
								<div
									className="partie-vendeur-banner"
									style={{
										background: `url(${banner}) no-repeat center center / cover`,
									}}
								>
									<div className="partie-vendeur-banner-content">
										<img src={logo} className="logo mb-2" alt="" />
										<h5 className="title">
											Bonjour {currentUser && currentUser.username}
										</h5>
									</div>
								</div>
								<div className="partie-vendeur-content">
									<div className="my-auto content">
										<div className="subtext">
											Sélectionnez l'action que vous souhaitez effectuer
										</div>

										<Link
											to="#"
											className="cmn-btn w-100 mb-3"
											onClick={() => handleButtonClick('scan')}
										>
											Scan QR Code
										</Link>
										<Link
											to="#"
											className="cmn-outline-btn w-100"
											onClick={() => handleButtonClick('new')}
										>
											Nouvelle entrée
										</Link>
										<Link
											to="#"
											className="cmn-outline-btn w-100 mt-3"
											onClick={() => handleButtonClick('results')}
										>
											Voir résultats
										</Link>
										{isLoading ? (
											<div
												className="cmn-btn w-100"
												style={{
													marginTop: '20px',
													marginBottom: '20px',
												}}
											>
												<LoadingSpinner height="80%" width="30%" />
											</div>
										) : (
											<FormControl
												fullWidth
												error={showroomError}
												style={{
													marginBottom: '20px',
													marginTop: '20px',
												}}
											>
												<Select
													value={selectedShowroom}
													className="cmn-outline-btn w-100"
													onChange={(e) => handleSelectShowroom(e.target.value)}
													displayEmpty
													required
												>
													<MenuItem value="" disabled>
														Selectionner un showroom
													</MenuItem>
													{showrooms.map((showroom) => (
														<MenuItem key={showroom.id} value={showroom.name}>
															{showroom.name}
														</MenuItem>
													))}
												</Select>
												{showroomError && (
													<FormHelperText>
														Veuillez sélectionner un showroom valide
													</FormHelperText>
												)}
											</FormControl>
										)}
									</div>
									<br />
									<br />

									<BlackLogo />
								</div>
							</>
							<ScanCode open={open} setOpen={setOpen} />
							<FormulaireTelVendeur open={open2} setOpen={setOpen2} />
							<Resultats
								open={open3}
								setOpen={setOpen3}
								selectedShowroom={selectedShowroom}
								currentUser={currentUser?.username}
							/>
						</div>
					</div>
				</>
			) : (
				<DashboardSales currentUser={currentUser?.username} />
			)}
		</>
	)
}

export default PartieVendeur
