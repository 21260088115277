import * as FileSaver from 'file-saver'
import {
	collection,
	onSnapshot,
	query,
	where,
	getDocs,
} from 'firebase/firestore'
import { useNavigate } from 'react-router-dom'
import React, { forwardRef, useEffect, useState } from 'react'
import { auth } from '../../firebase'
import DatePicker from 'react-datepicker'
import { Link } from 'react-router-dom'
import XLSX from 'sheetjs-style'
import * as moment from 'moment'
import 'moment/locale/fr'
import fr from 'date-fns/locale/fr'
import { subMonths } from 'date-fns'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'

import 'react-datepicker/dist/react-datepicker.css'
import { ArrowBottom, ArrowRight } from '../../components/Icon'
import { db } from '../../firebase'
import DashboardHeader from './DashboardHeader'

const DashboardScores = () => {
	const navigate = useNavigate()
	const [snapShot, setSnapShot] = useState(null)
	const [customers, setCustomers] = useState([])
	const [sellers, setSellers] = useState([])
	const [dates, setDates] = useState([])
	const [showroomsData, setShowroomsData] = useState([])
	const [salons, setSalons] = useState([])
	const [startDate, setStartDate] = useState(subMonths(new Date(), 1))
	const [endDate, setEndDate] = useState(new Date())
	const [selectedShowroom, setSelectedShowroom] = useState(false)

	const [password, setPassword] = useState('')
	const [authenticated, setAuthenticated] = useState(false)

	const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
		<button className="export-button" onClick={onClick} ref={ref}>
			{value || 'Select Date'}
		</button>
	))

	useEffect(() => {
		const unsubscribe = auth.onAuthStateChanged((user) => {
			if (user) {
				// User is authenticated, allow access to protected routes
				navigate('/dashboard-scores')
			} else {
				// User is not authenticated, redirect to login page
				navigate('/login')
			}
		})

		return () => unsubscribe()
	}, [navigate])

	const fileType =
		'application/vnd.openxmlformats-officedocument.spreadseetml.sheet;charset=UTF-8'
	const fileExtension = '.xlsx'

	const exportToExcel = async () => {
		const ws = XLSX.utils.json_to_sheet(customers)
		const wb = { Sheets: { data: ws }, SheetNames: ['data'] }
		const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
		const data = new Blob([excelBuffer], { type: fileType })
		FileSaver.saveAs(data, 'clients' + fileExtension)
	}

	// Helper function to get the month index
	function getMonthIndex(monthString) {
		const months = [
			'janvier',
			'février',
			'mars',
			'avril',
			'mai',
			'juin',
			'juillet',
			'août',
			'septembre',
			'octobre',
			'novembre',
			'décembre',
		]
		return months.findIndex(
			(month) => month.toLowerCase() === monthString.toLowerCase()
		)
	}

	const fetchShowrooms = async () => {
		try {
			const q = query(collection(db, 'Showrooms'))
			let salonsData = []

			const querySnapshot = await getDocs(q)
			if (querySnapshot.empty) {
				console.log('No documents found')
			} else {
				querySnapshot.forEach((doc) => {
					salonsData.push({
						name: doc.data().name,
						id: doc.id,
					})
				})
				setSalons(salonsData)
			}
			console.log('Doc found')
		} catch (error) {
			console.error('Error fetching showrooms:', error)
		}
	}

	useEffect(() => {
		fetchShowrooms()
	}, [])

	useEffect(() => {
		const q = query(
			collection(db, 'Customers'),
			where('project', '!=', 'Inactif')
		)

		const unsuscribe = onSnapshot(q, (querySnapshot) => {
			setSnapShot(querySnapshot)
		})
		return () => {
			unsuscribe()
		}
	}, [])

	useEffect(() => {
		if (snapShot) {
			const sellersMap = new Map()
			const showroomSet = new Set()
			const datesSet = new Set()
			const totalCountPerDate = new Map()

			snapShot.docs.forEach((doc) => {
				const showroom = doc.data().showroom
				console.log(showroom)
				if ((showroom && !selectedShowroom) || showroom === selectedShowroom) {
					const dateString = doc.data().createdAt
					const dateParts = dateString.split(' ')
					const day = parseInt(dateParts[0])
					const month = getMonthIndex(dateParts[1])
					const year = parseInt(dateParts[2])

					// Create the Date object
					const date = new Date(year, month, day)

					const isoDate = date.toISOString() // Convert the date to ISO string

					const sellerName = doc.data().vendeur

					const isDateInRange = startDate <= date && date <= endDate

					if (isDateInRange) {
						datesSet.add(isoDate)

						if (sellersMap.has(sellerName)) {
							const seller = sellersMap.get(sellerName)
							const record = seller.records.find((r) => r.date === isoDate)

							if (record) {
								record.count += 1
							} else {
								seller.records.push({
									date: isoDate,
									count: 1,
								})
							}
						} else {
							sellersMap.set(sellerName, {
								name: sellerName,
								records: [
									{
										date: isoDate,
										count: 1,
									},
								],
							})
						}
					}
				}
			})

			sellersMap.forEach((seller) => {
				seller.records.forEach((record) => {
					const { date, count } = record
					if (totalCountPerDate.has(date)) {
						totalCountPerDate.set(date, totalCountPerDate.get(date) + count)
					} else {
						totalCountPerDate.set(date, count)
					}
				})
			})

			// Add the "Total" item to sellersMap
			const totalItem = {
				name: 'Total',
				records: Array.from(totalCountPerDate.entries()).map(
					([date, count]) => ({
						date,
						count,
					})
				),
			}
			sellersMap.set(totalItem.name, totalItem)

			const sellersArray = Array.from(sellersMap.values())
			setSellers(sellersArray)

			// Sort dates chronologically
			const datesArray = Array.from(datesSet)
			datesArray.sort((a, b) => new Date(a) - new Date(b)) // Sort dates in ascending order
			setDates(datesArray)
		}
	}, [snapShot, startDate, endDate, selectedShowroom])

	return (
		<>
			<DashboardHeader />
			<div
				className="container"
				style={{ '--title:': '#111928', fontFamily: 'var(--inter)' }}
			>
				<div className="dashboard-body">
					<div className="d-flex flex-wrap justify-content-between align-items-center gap-3 mb-41">
						<h4 className="mb-4 mt-4">Scores total des vendeurs</h4>
					</div>
					<div className="d-flex flex-wrap gap-3 justify-content-end">
						<div
							style={{
								display: 'flex',
								alignItems: 'center',
							}}
						>
							<h6 style={{ marginRight: '10px' }}>Salon</h6>
							<Select
								value={selectedShowroom}
								onChange={(e) => setSelectedShowroom(e.target.value)}
								MenuProps={{
									disablePortal: true,
								}}
							>
								<MenuItem value={false}>Tous</MenuItem>
								{salons.map((salon) => (
									<MenuItem key={salon.id} value={salon.name}>
										{salon.name}
									</MenuItem>
								))}
							</Select>
						</div>
						<div
							style={{
								marginLeft: '20px',
								display: 'flex',
								alignItems: 'center',
							}}
						>
							<h6 style={{ marginRight: '10px' }}>De</h6>
							<DatePicker
								selected={startDate}
								onChange={(date) => setStartDate(date)}
								selectsStart
								startDate={startDate}
								endDate={endDate}
								customInput={<ExampleCustomInput />}
								locale={fr}
								dateFormat="dd/MM/yyyy"
							/>
						</div>
						<div
							style={{
								display: 'flex',
								alignItems: 'center',
							}}
						>
							<h6 style={{ marginRight: '10px' }}>A</h6>
							<DatePicker
								selected={endDate}
								onChange={(date) => setEndDate(date)}
								selectsEnd
								startDate={startDate}
								endDate={endDate}
								minDate={startDate}
								customInput={<ExampleCustomInput />}
								locale={fr}
								dateFormat="dd/MM/yyyy"
							/>
						</div>
					</div>
					<br />
					<div className="table-responsive">
						<table className="table __table table--bordered">
							<thead>
								<tr>
									<th>
										<div className="direction">
											<div className="text-end">
												<div>
													&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Date créa.
												</div>
												<div>
													<ArrowRight />
												</div>
											</div>
											<div className="d-flex align-items-center gap-1">
												<div>
													<ArrowBottom />
												</div>
												<div>User</div>
											</div>
										</div>
									</th>
									{dates?.map((date, index) => (
										<th key={index}>
											<div className="border-name">
												<h6 className="name">{moment(date).format('LL')}</h6>
											</div>
										</th>
									))}
									<th>
										<div className="border-name">
											<h6 className="name">Total /vendeur</h6>
										</div>
									</th>
								</tr>
							</thead>
							<tbody>
								{sellers?.map((item) => (
									<tr>
										<td>
											<div className="border-name">
												<h6 className="name">{item.name}</h6>
											</div>
										</td>
										{dates?.map((date, index) => (
											<th key={index}>
												<div>
													<h6 className="count">
														{item.records.find((record) => record.date === date)
															?.count || 0}
													</h6>
												</div>
											</th>
										))}
										<th>
											<div>
												<h6 className="total">
													{item.records.reduce(
														(total, record) => total + record.count,
														0
													)}
												</h6>
											</div>
										</th>
									</tr>
								))}
							</tbody>
						</table>
					</div>
					<br />
					<br />
					<br />
				</div>
			</div>
		</>
	)
}

export default DashboardScores
