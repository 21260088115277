import React, { useState, useEffect } from 'react'
import { collection, query, where, onSnapshot } from 'firebase/firestore'
import 'moment/locale/fr'
import { db } from '../../firebase'
import { ClearIcon } from '../../components/Icon'

const Resultats = ({ open, setOpen, selectedShowroom, currentUser }) => {
	const [snapShot, setSnapShot] = useState(null)
	const [sellers, setSellers] = useState([])
	const [customers, setCustomers] = useState([])
	const [activeTab, setActiveTab] = useState('sellers') // 'sellers' | 'leads'

	useEffect(() => {
		if (!selectedShowroom) return

		const q = query(
			collection(db, 'Customers'),
			where('showroom', '==', selectedShowroom)
		)

		const unsubscribe = onSnapshot(q, (querySnapshot) => {
			setSnapShot(querySnapshot)
		})
		return () => unsubscribe()
	}, [selectedShowroom])

	useEffect(() => {
		if (snapShot) {
			let records = []
			let newArray = []

			snapShot.docs.forEach((doc) => {
				const data = doc.data()
				records.push(data)

				const sellerIndex = newArray.findIndex(
					(item) => item.name === data.vendeur
				)

				if (sellerIndex >= 0) {
					newArray[sellerIndex].quantity += 1
				} else {
					newArray.push({ name: data.vendeur, quantity: 1 })
				}
			})

			newArray.sort((a, b) => b.quantity - a.quantity)

			setCustomers(records)
			setSellers(newArray)
		}
	}, [snapShot])

	return (
		<div className={`qr-code-wrapper ${open ? 'open' : ''}`}>
			<div
				className="backdrop backdrop-border"
				onClick={() => setOpen(false)}
				style={{ height: '122px' }}
			></div>

			<div className="qr-code-inner">
				<div className="d-flex justify-content-between align-items-center">
					<h3 className="title--small">Résultats</h3>
					<button
						className="border-0 outline-0 bg-transparent"
						type="button"
						onClick={() => setOpen(false)}
					>
						<ClearIcon />
					</button>
				</div>

				<div className="tab-buttons my-3 d-flex gap-2">
					<button
						className={`btn ${
							activeTab === 'leads' ? 'cmn-btn' : 'cmn-outline-btn'
						}`}
						onClick={() => setActiveTab('leads')}
					>
						Mes Leads
					</button>
					<button
						className={`btn ${
							activeTab === 'sellers' ? 'cmn-btn' : 'cmn-outline-btn'
						}`}
						onClick={() => setActiveTab('sellers')}
					>
						Top Vendeurs
					</button>
				</div>

				<div className="table-responsive">
					{activeTab === 'sellers' ? (
						<table className="table table-striped table-bordered">
							<thead className="thead-dark">
								<tr>
									<th>Nom du Vendeur</th>
									<th>Nombre de Ventes</th>
								</tr>
							</thead>
							<tbody>
								{sellers.map((item, index) => (
									<tr key={index}>
										<td>{item.name}</td>
										<td>{item.quantity}</td>
									</tr>
								))}
							</tbody>
						</table>
					) : (
						<table className="table table-striped table-bordered">
							<thead className="thead-dark">
								<tr>
									<th>Nom Client</th>
									<th>Prénom</th>
									<th>Email</th>
									<th>Téléphone</th>
									<th>Projet</th>
									<th>Statut</th>
									<th>Vendeur</th>
								</tr>
							</thead>
							<tbody>
								{customers
									.filter((customer) => customer.vendeur === currentUser)
									.map((customer, index) => (
										<tr key={index}>
											<td>{customer.name}</td>
											<td>{customer.firstName}</td>
											<td>{customer.mail}</td>
											<td>{customer.phoneNumber}</td>
											<td>{customer.project}</td>
											<td>{customer.stat || '-'}</td>
											<td>{customer.vendeur}</td>
										</tr>
									))}
							</tbody>
						</table>
					)}
				</div>
			</div>
		</div>
	)
}

export default Resultats
